import { useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  Slide,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  Zoom,
  styled,
  Tooltip,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
// import useRefMounted from 'src/hooks/useRefMounted';
import Label from 'src/components/Label';
import { useSnackbar } from 'notistack';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { baseUrl } from 'src/baseUrl';
import axios from 'axios';
import PageHeader from './PageHeader';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ImgWrapper = styled('img')(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: auto;
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (products, query) => {
  return products.filter((product) => {
    let matches = true;

    if (query) {
      const properties = ['name'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (typeof (product[property]) === 'string' && product[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (products, page, limit) => {
  return products.slice(page * limit, page * limit + limit);
};

const Results = ({ users, setUsers, datafetch }) => {
  // const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  let first = useRef(null)

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    setPage(0)
  };

  const filteredProducts = applyFilters(users, query);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  // const getUsers = async () => {
  //   try {
  //     const response = await axios.post(`${baseUrl}shopping/categories`);
  //     if (isMountedRef.current) {
  //       setUsers(response.data);
  //     }
  //   }
  //   catch (err) {
  //     console.error(err);
  //     console.log("Error", err.response.data);
  //     enqueueSnackbar((err.response.data.message), {
  //       variant: 'error',
  //       anchorOrigin: {
  //         vertical: 'top',
  //         horizontal: 'right'
  //       },
  //       TransitionComponent: Zoom
  //     });
  //   }
  // }

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('You successfully deleted the product'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const handleEdit = (x) => {
    first.current.childFunction1(x);
    console.log(x);
  }

  const EnableProduct = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}shopping/categories/enable`, { "id": id });
      console.log(response);
      enqueueSnackbar((response.data.data, 'Enable Category Succesfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
      datafetch()
    }
    catch (err) {
      console.error(err);
      console.log("Error", err.response.data);
      enqueueSnackbar((err.response.data.message), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }
  }

  const DisableProduct = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}shopping/categories/disable`, { "id": id });
      enqueueSnackbar((response.data.data, 'Disable Category Succesfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
      datafetch()
    }
    catch (err) {
      console.error(err);
      console.log("Error", err.response.data);
      enqueueSnackbar((err.response.data.message), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }
  }

  return (
    <Typography style={{ paddingTop: '70px' }}>
      <Card>
        <Box display="flex" alignItems="center">
          <Box
            flex={1}
            p={2}
            display={{ xs: 'block', md: 'flex' }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                mb: { xs: 2, md: 0 }
              }}
            >
              <TextField
                size="small"
                fullWidth={mobile}
                onChange={handleQueryChange}
                value={query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                placeholder={t('Search by category name...')}
              />
            </Box>
            <PageHeader ref={first} datafetch={datafetch} users={users} setUsers={setUsers} />
          </Box>
        </Box>
        <Divider />

        {paginatedProducts.length === 0 ? (
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {t("We couldn't find any products matching your search criteria")}
          </Typography>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('No. ')}</TableCell>
                    <TableCell>{t('Image')}</TableCell>
                    <TableCell>{t('Name')}</TableCell>
                    <TableCell>{t('Action')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedProducts.map((product, index) => {
                    return (
                      <TableRow
                        hover
                        key={product.id}
                      >
                        <TableCell align="center">
                          <Label color="success">
                            {(page * limit) + index + 1}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <ImgWrapper src={product.image} />
                          </Box>
                        </TableCell>
                        <TableCell >
                          <Typography variant="subtitle2" noWrap>
                            {product.name}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography noWrap>
                            <Tooltip title={t('Edit')} arrow>
                              <IconButton color="primary" onClick={() => { handleEdit(product) }} >
                                <Button variant="contained" color="primary">Edit</Button>
                              </IconButton>
                            </Tooltip>
                          </Typography>
                          <Typography noWrap>
                            <Button type="submit">
                              {product.active === 1 ?
                                <Button variant="contained" color="error" onClick={() => DisableProduct(product.id)}>Disable</Button>
                                :
                                <Button variant="contained" color="success" onClick={() => EnableProduct(product.id)}>Enable</Button>
                              }
                            </Button>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredProducts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15, 100]}
              />
            </Box>
          </>
        )}
      </Card>
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Do you really want to delete this product')}?
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            {t("You won't be able to revert after deletion")}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </Typography>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired
};

Results.defaultProps = {
  users: []
};

export default Results;
