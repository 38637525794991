import axios from 'axios'

const axiosInstnce = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

axiosInstnce.interceptors.request.use((req) => {
    req.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    // console.log(req);
    return req;
},
    (err) => {
        debugger //eslint-disable-line
        console.log(err, "err");
        return Promise.reject(err);
    }
);

axiosInstnce.interceptors.response.use(
    (res) => {
        // Add configurations here
        if (res.status === 401) {
            console.log('Posted Successfully');
            localStorage.clear()
            window.location.href = '/account/login'
        }
        return res;
    },
    (err) => {
        console.log(err);
        // debugger // eslint-disable-line
        if (err && err.response && err.response.status === 401) {
            localStorage.clear()
            window.location.href = '/account/login'
        }
        return Promise.reject(err);
    }
);
export default axiosInstnce
