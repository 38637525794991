import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Banner from 'src/content/management/Products/Banner';
import CategoryList from 'src/content/management/Products/Category';
import Predictawin from 'src/content/management/Products/PredictAndWin';
import Quiz from 'src/content/management/Products/Quiz';
import Spin from 'src/content/management/Products/Spin';
// import AllUser from 'src/content/management/Products/AllUser';
// import CoinList from 'src/content/management/Products/Coin';
// import KycList from 'src/content/management/Products/KycList';
// import Payment from 'src/content/management/Products/Payment';
// import PurChaseOrder from 'src/content/management/Products/Purchase';
// import PurchaseDetails from 'src/content/management/Products/PurchaseDetails';
// import Transection from 'src/content/management/Products/Transection';
// import TransectionTab from 'src/content/management/Products/TransectionTab';
// import Withdraw from 'src/content/management/Products/Withdraw';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Management

// const Users = Loader(lazy(() => import('src/content/management/Users')));
// const SingleUser = Loader(
//   lazy(() => import('src/content/management/Users/single'))
// );
// const Projects = Loader(lazy(() => import('src/content/management/Projects')));
const Invoices = Loader(lazy(() => import('src/content/management/Invoices')));
const SingleInvoice = Loader(
  lazy(() => import('src/content/management/Invoices/single'))
);
const Products = Loader(lazy(() => import('src/content/management/Products')));
const Coin = Loader(lazy(() => import('src/content/management/Products/Coin')));
const Withdraw = Loader(lazy(() => import('src/content/management/Products/Withdraw')));
const Payment = Loader(lazy(() => import('src/content/management/Products/Payment')));
const Transection = Loader(lazy(() => import('src/content/management/Products/Transection')));
const TransectionTab = Loader(lazy(() => import('src/content/management/Products/TransectionTab')));
const PurChaseOrder = Loader(lazy(() => import('src/content/management/Products/Purchase')));
const PurchaseDetails = Loader(lazy(() => import('src/content/management/Products/PurchaseDetails')));
const KycList = Loader(lazy(() => import('src/content/management/Products/KycList')));
const AllUser = Loader(lazy(() => import('src/content/management/Products/AllUser')));
const CreateProduct = Loader(
  lazy(() => import('src/content/management/Products/create'))
);
const SingleProduct = Loader(
  lazy(() => import('src/content/management/Products/single'))
);
const Shop = Loader(lazy(() => import('src/content/management/Products/shop')));

const managementRoutes = [
  {
    path: 'commerce',
    children: [
      {
        path: '/',
        element: <Navigate to="shop" replace />
      },
      {
        path: 'shop',
        element: <Shop />
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Products />
          },
          {
            path: 'create',
            element: <CreateProduct />
          },
          {
            path: 'coin',
            element: <Coin />
          },
          {
            path: 'withdraw',
            element: <Withdraw />
          },
          {
            path: 'payment',
            element: <Payment />
          },
          {
            path: 'transection',
            element: <Transection />
          },
          {
            path: 'transection_tab',
            element: <TransectionTab />
          },
          {
            path: 'purchase_order',
            element: <PurChaseOrder />
          },
          {
            path: 'purchase_details',
            element: <PurchaseDetails />
          },
          {
            path: 'kyc_list',
            element: <KycList />
          },
          {
            path: 'user_list',
            element: <AllUser />
          },
          {
            path: 'category_list',
            element: <CategoryList />
          },
          {
            path: 'banner',
            element: <Banner />
          },
          {
            path: 'spin',
            element: <Spin />
          },
          {
            path: 'quiz',
            element: <Quiz />
          },
          {
            path: 'predict_win',
            element: <Predictawin />
          },
          {
            path: 'single',
            children: [
              {
                path: '/',
                element: <Navigate to="1" replace />
              },
              {
                path: ':productId',
                element: <SingleProduct />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'invoices',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Invoices />
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':invoiceId',
            element: <SingleInvoice />
          }
        ]
      }
    ]
  }
];

export default managementRoutes;
