import {
  useState, forwardRef,
  //  useEffect,
  useRef
} from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Grid,
  DialogTitle,
  // Select,
  // MenuItem,
  DialogContentText,
  Card,
  Slide,
  Divider,
  InputAdornment,
  Table,
  Tooltip,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  Zoom,
  styled,
  CircularProgress,
  DialogActions,
  DialogContent,
  Chip
} from '@mui/material';
import { Formik, ErrorMessage } from 'formik';
import wait from 'src/utils/wait';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
// import useRefMounted from 'src/hooks/useRefMounted';
import Label from 'src/components/Label';
import { useSnackbar } from 'notistack';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { baseUrl } from 'src/baseUrl';
import axios from 'axios';
import PageHeader from './PageHeader';
import './quiz.css'

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (products, query) => {
  return products.filter((product) => {
    let matches = true;

    if (query) {
      const properties = ['gameTitle'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (typeof (product[property]) === 'string' && product[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (products, page, limit) => {
  return products.slice(page * limit, page * limit + limit);
};

const Results = ({ users, setUsers, datafetch }) => {
  // const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  let first = useRef(null)

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [opentabel, setOpentabel] = useState(false)
  const [tableData, setTableData] = useState([])
  const [initialvalue, setinitialvalue] = useState({ questions: [{ question: '', answer: '', options: [] }], })
  const [initialTitle, setInitialTitle] = useState({ title: '' })
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false)
  const [dynamicMenu, setDynamicMenu] = useState({ value: '', name: '' })
  const [editData, setEditData] = useState([])
  const [editTitleData, setEditTitleData] = useState([])

  const onDynamicMenuChange = (e) => {
    setDynamicMenu({ ...dynamicMenu, [e.target.name]: e.target.value, value: e.target.value })
  }

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    setPage(0)
  };

  const filteredProducts = applyFilters(users, query);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);

  const refresh = () => {
    let arr = initialvalue.questions;
    setinitialvalue({ questions: arr });
  }

  const handleEditTitle = (x) => {
    setOpenTitle(true)
    setEditTitleData(x)
    setInitialTitle({ title: x.gameTitle })
  }

  const titleRefresh = () => {
    let titlearr = initialTitle.title
    setInitialTitle({ title: titlearr })
  }

  const handleOpenTabel = async (id) => {
    setOpentabel(true);
    setTableData(id)
  }

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleCreateUserClose = () => {
    setOpen(false);
    setOpenProduct(false);
    setinitialvalue({
      questions: [{ questionId: '', question: '', answer: '', options: [] }],
    })
  };

  const handleTitleClose = () => {
    setOpenTitle(false)
  }

  const toastedit = (e) => {
    return e ? `The Quiz was Updated successfully` : `Error Updating Quiz`
  }

  const handleClickOpen = () => {
    setOpenProduct(true);
  };

  const handleClose = () => {
    setOpenProduct(false);
  };

  const handleEdit = (x) => {
    setOpen(true)
    setEditData(x)
  }

  const handleEditCall = () => {
    let params = {};
    Object.assign(params, { "gameId": tableData.gameId });
    Object.assign(params, { "questionId": editData.questionId });

    let data = {};
    Object.assign(data, { "question": editData.question });
    Object.assign(data, { "options": editData.options });
    Object.assign(data, { "answer": editData.answer });

    Object.assign(params, { "question": data });
    axios.post(`${baseUrl}quiz/edit/questions`, params).then((response) => {
      enqueueSnackbar(t(toastedit(response.data)), {
        variant: response.data ? 'success' : 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        TransitionComponent: Zoom, autoHideDuration: 2000
      });
      setOpen(false);
      handleClickOpen()
      datafetch()
    })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar((err.response.data.message), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      })
  }

  const handleEditTitleCall = () => {
    axios.post(`${baseUrl}quiz/edit/title`, { "gameId": editTitleData.gameId, "title": initialTitle.title })
      .then((response) => {
        enqueueSnackbar(t(toastedit(response.data)), {
          variant: response.data ? 'success' : 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          TransitionComponent: Zoom, autoHideDuration: 2000
        });
        setOpenTitle(false);
        handleClickOpen()
        datafetch()
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar((err.response.data.message), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      })
  }

  const modalbtn = () => {
    handleEditCall()
  }
  const modalTitlebtn = (_values) => {
    handleEditTitleCall(_values)
  }

  const handleCloseTabel = () => {
    setOpentabel(false);
  }

  const handleCreateUserSuccess = () => {
    setOpen(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('You successfully deleted the product'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const DeleteQuiz = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}quiz/delete`, { "gameId": id });
      enqueueSnackbar((response.data.data, 'Delete Quiz Succesfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
      datafetch()
    }
    catch (err) {
      console.error(err);
      enqueueSnackbar((err.response.data.message), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }
  }

  return (
    <Typography style={{ paddingTop: '80px' }}>
      <Card>
        <Box display="flex" alignItems="center">
          <Box
            flex={1}
            p={2}
            display={{ xs: 'block', md: 'flex' }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ mb: { xs: 2, md: 0 } }}>
              <TextField
                size="small"
                fullWidth={mobile}
                onChange={handleQueryChange}
                value={query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                placeholder={t('Search by category name...')}
              />
            </Box>
            <PageHeader ref={first} datafetch={datafetch} users={users} setUsers={setUsers} />
          </Box>
        </Box>
        <Divider />

        {paginatedProducts.length === 0 ? (
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {t("We couldn't find any products matching your search criteria")}
          </Typography>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('No. ')}</TableCell>
                    <TableCell>{t('Game Title')}</TableCell>
                    <TableCell>{t('Question')}</TableCell>
                    <TableCell>{t('Option')}</TableCell>
                    <TableCell>{t('Answer')}</TableCell>
                    <TableCell>{t('Action')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedProducts?.map((product, index) => {
                    return (
                      <TableRow
                        hover
                        key={product.id}
                      >
                        <TableCell align="center">
                          <Label color="success">
                            {(page * limit) + index + 1}
                          </Label>
                        </TableCell>
                        <TableCell >
                          <Typography variant="subtitle2" noWrap>
                            {product.gameTitle}
                          </Typography>
                        </TableCell>
                        <TableCell >
                          {product?.questions.map(e => e.question).join(", ")}
                        </TableCell>
                        <TableCell >
                          {product?.questions.map(e => e.options).join(", ")}
                        </TableCell>
                        <TableCell >
                          <Typography variant="subtitle2" noWrap>
                            {product?.questions.map(e => e.answer).join(", ")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography noWrap>
                            <Box display="flex" alignItems="center" style={{ marginBottom: '5px' }}>
                              <Button
                                variant="contained"
                                onClick={() => handleOpenTabel(product)}
                              >
                                View
                              </Button>
                            </Box>
                          </Typography>
                          <Typography noWrap>
                            <Box display="flex" alignItems="center" style={{ marginBottom: '5px' }}>
                              <Button
                                variant="contained"
                                color='success'
                                onClick={() => { handleEditTitle(product) }}
                              >
                                Edit Title
                              </Button>
                            </Box>
                          </Typography>
                          <Typography noWrap>
                            <Button
                              variant="contained"
                              onClick={() => { DeleteQuiz(product.gameId) }}
                              color="error"
                            >
                              Delete
                            </Button>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredProducts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15, 100]}
              />
            </Box>
          </>
        )}
      </Card>
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Do you really want to delete this product')}?
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            {t("You won't be able to revert after deletion")}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
      <DialogWrapper
        style={{ overflow: 'auto', zIndex: 100, paddingTop: '50px' }}
        open={opentabel}
        onClose={handleCloseTabel}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        align="center"
      >
        <Card sx={{ minWidth: 275, }} >
          <TableContainer sx={{ maxHeight: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{t('No.')}</TableCell>
                  <TableCell>{t('Question')}</TableCell>
                  <TableCell>{t('Option')}</TableCell>
                  <TableCell>{t('Answer')}</TableCell>
                  <TableCell>{t('Action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.questions?.map((product, index) => {
                  return (
                    <>
                      <TableRow
                        hover
                        key={product.id}
                      >
                        <TableCell style={{
                          position: 'sticky'
                        }}>
                          <Box display="flex" alignItems="center">
                            <Box
                              pl={1}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {index + 1}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell style={{ position: 'sticky' }}>
                          <Box pl={1}>
                            <Typography variant="subtitle2" noWrap>
                              {product.question}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell style={{ position: 'sticky' }}>
                          <Box
                            pl={1}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {product.options.join(", ")}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell style={{
                          position: 'sticky'
                        }}>
                          <Box
                            pl={1}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {product.answer}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell style={{ position: 'sticky' }}>
                          <Box pl={1}>
                            <Typography noWrap>
                              <Tooltip title={t('Edit')} arrow>
                                <IconButton color="primary" onClick={() => { handleEdit(product) }} >
                                  <CreateIcon fontSize="small" />
                                </IconButton >
                              </Tooltip >
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </DialogWrapper>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleCreateUserClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            Update Quiz
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new Project to the site'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={editData}
          validationSchema={Yup.object().shape({
            questions: Yup.array().of(
              Yup.object().shape({
                question: Yup.string().required("Question required"),
                answer: Yup.string().required("Answer required"),
                options: Yup.array()
                  .required("options required")
                  .min(4, 'Minimum of 4 options')
                  .max(4, 'Maximum of 4 options')
              }))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              await wait(1000);
              setStatus({ success: true });
              setSubmitting(false);
              handleEditCall(_values)
              resetForm();
              setinitialvalue({ questions: [{ question: '', answer: '', options: [] }], })
              handleCreateUserSuccess();
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            // handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            index
          }) => (
            <form form onSubmit={handleSubmit}>
              <DialogContent dividers sx={{ p: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.question && errors.question)}
                      fullWidth
                      helperText={touched.question && errors.question}
                      label={t('Question')}
                      name="question"
                      onBlur={handleBlur}
                      value={values.question}
                      onChange={(ef) => {
                        values.question = ef.target.value;
                        refresh();
                      }}
                      variant="outlined"
                    />
                    <ErrorMessage name={`questions.${index}.question`} >
                      {msg => <div style={{ color: 'red' }}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t('Add Menu')}
                      name="name"
                      onBlur={handleBlur}
                      value={dynamicMenu.name}
                      onChange={onDynamicMenuChange}
                      variant="outlined"
                    />

                    <Button onClick={() => {
                      let arr = values.options;
                      values.options = [...arr, dynamicMenu.name];
                      refresh();
                    }}>
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    {values.options.map((nametemp) => {
                      return (
                        <Chip
                          key={nametemp}
                          label={nametemp}
                          value={nametemp}
                          onDelete={() => {
                            let arr = values.options;
                            let indexOf = arr.indexOf(nametemp)
                            arr.splice(indexOf, 1)
                            values.options = arr;
                            refresh()
                          }}
                        >
                          {nametemp}
                        </Chip>
                      )
                    })}
                    <ErrorMessage name={`questions.${index}.options`} >
                      {msg => <div style={{ color: 'red' }}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.answer && errors.answer)}
                      fullWidth
                      helperText={touched.answer && errors.answer}
                      label={t('Answer')}
                      name="answer"
                      onBlur={handleBlur}
                      value={values.answer}
                      onChange={(ef) => {
                        values.answer = ef.target.value;
                        refresh();
                      }}
                      variant="outlined"
                      type='number'
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateUserClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                  onClick={modalbtn}
                >
                  Update Quiz
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openTitle}
        onClose={handleTitleClose}
      >
        <DialogTitle sx={{ p: 3 }} >
          <Typography variant="h4" gutterBottom>
            Update Game Title
          </Typography>
          <Typography variant="subtitle2">
            {t('Fill in the fields below to create and add a new Project to the site')}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={editTitleData}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .max(255)
              .required(t('Game Title is required!')),
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              await wait(1000);
              setStatus({ success: true });
              setSubmitting(false);
              handleEditTitleCall(_values)
              resetForm();
              setInitialTitle({ gameTitle: '' })
              handleCreateUserSuccess();
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            // handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            // values,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers sx={{ p: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      label={t('Game Title')}
                      name="title"
                      onBlur={handleBlur}
                      value={initialTitle.title}
                      onChange={(ef) => {
                        initialTitle.title = ef.target.value;
                        titleRefresh();
                      }}
                      variant="outlined"
                    />
                    {/* <ErrorMessage name='title' >
                      {msg => <div style={{ color: 'red' }}>{msg}</div>}
                    </ErrorMessage> */}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleTitleClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                  onClick={modalTitlebtn}
                >
                  Update GameTitle
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog open={openProduct} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Quiz successfully update
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button variant='contained' color='success' onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Typography>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired
};

Results.defaultProps = {
  users: []
};

export default Results;
