import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { baseUrl } from 'src/baseUrl';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import { useSnackbar } from 'notistack';
import { Grid, Zoom } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';
import Results from './Results';

function Predictawin() {
  const isMountedRef = useRefMounted();
  let first = useRef(null)
  const [users, setUsers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getUsers = async () => {
    try {
      const response = await axios.post(`${baseUrl}paw/questions/all`);
      if (isMountedRef.current) {
        setUsers(response.data);
      }
    }
    catch (err) {
      console.error(err);
      enqueueSnackbar((err.response.data.message), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    }
  }

  useEffect(() => {
    getUsers();
  }, []);


  return (
    <>
      <Helmet>
        <title>Product Managment</title>
      </Helmet>
      {/* <PageTitleWrapper>
        <PageHeader ref={first} />
      </PageTitleWrapper> */}

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results users={users} openmodal={first.current} setUsers={setUsers} datafetch={getUsers} />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default Predictawin;