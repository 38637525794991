/* eslint-disable jsx-a11y/label-has-for */
import { forwardRef, useImperativeHandle, useState } from 'react';
// import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import wait from 'src/utils/wait';
import { baseUrl } from 'src/baseUrl';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  // Radio,
  TextField,
  // FormControlLabel,
  CircularProgress,
  Button,
  DialogContentText,
  // IconButton,
  // MenuItem,
} from '@mui/material';
import axios from 'axios';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useSnackbar } from 'notistack';
import useRefMounted from 'src/hooks/useRefMounted';

const PageHeader = forwardRef((props, ref) => {

  const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState('')
  // const [previousImage, setPreviousImage] = useState('')
  const [initialvalue, setinitialvalue] = useState({ name: '', image })
  const [previewImage, setPreviewImage] = useState([])
  const [openCategory, setOpenCategory] = useState(false)

  const handleClickOpen = () => {
    setOpenCategory(true);
  };

  const handleClose = () => {
    setOpenCategory(false);

  };
  useImperativeHandle(ref, () => ({
    childFunction1(x) {
      setOpen(true);
      console.log(x);
      setPreviewImage(x.image)
      setinitialvalue(x)
    },
  }))

  const handleCreateUserOpen = () => {
    setOpen(true);
  };

  const handleCreateUserClose = () => {
    setOpen(false);
    setinitialvalue({
      name: '', image
    })
    setPreviewImage([])
  };

  const imageAdd = (e) => {
    return e ? `The Image was successfully Select` : `Error Image select`
  }
  const toastadd = (e) => {
    return e ? `The Category was Added successfully` : `Error Creating Category`
  }
  const toastedit = (e) => {
    return e ? `The Category was Updated successfully` : `Error Updating Category`
  }

  const modalbtn = () => {
    return !initialvalue.id ? `Add new Category` : `Update Category`
  }

  const modalTitle = (e) => {
    return e ? `Category successfully created.` : `Category successfully update.`
  }

  const handleCreateUserSuccess = () => {
    setOpen(false);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0])
    setinitialvalue({ ...initialvalue, 'image': e.target.files[0] })
    if (e.target.files && e.target.files[0]) {
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
    enqueueSnackbar(t(imageAdd(e.target.files)), {
      variant: e.target.files ? 'success' : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      TransitionComponent: Zoom, autoHideDuration: 2000
    });

  }

  const getUsers = async () => {
    try {
      const response = await axios.post(`${baseUrl}shopping/categories`);
      if (isMountedRef.current) {
        props.setUsers(response.data);
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  const handleAddCall = (_values) => {
    let data = new FormData();
    data.append('cat_image', image);
    data.append('name', _values.name);

    axios.post(`${baseUrl}shopping/category/add`, data).then((response) => {
      console.log('response', response)
      enqueueSnackbar(t(toastadd(response.data)), {
        variant: response.data ? 'success' : 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        TransitionComponent: Zoom, autoHideDuration: 2000
      });
      setOpen(false);
      setinitialvalue({ name: '', image: [] })
      handleClickOpen()
      getUsers()
    })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar((err.response.data.message), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      })
  }

  const handleEditCall = (_values) => {
    let data = new FormData();
    data.append('id', _values.id);
    data.append('cat_image', image);
    data.append('name', _values.name);

    axios.post(`${baseUrl}shopping/category/update`, data).then((response) => {
      enqueueSnackbar(t(toastedit(response.data)), {
        variant: response.data ? 'success' : 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        TransitionComponent: Zoom, autoHideDuration: 2000
      });
      setinitialvalue({ name: '', image: [] })
      setOpen(false);
      handleClickOpen()
      getUsers()
    })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar((err.response.data.message), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      })
  }

  const handleApiCall = (values) => {
    return !values.id ? handleAddCall(values) : handleEditCall(values)
  }

  return (
    <>
      <Grid container justifyContent="end" alignItems="center">
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={handleCreateUserOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Create Category')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        style={{ zIndex: 10000 }}
        open={open}
        onClose={handleCreateUserClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t(initialvalue.id ? 'Update Category' : 'Add New Categorys')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new Project to the site'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={initialvalue}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              await wait(1000);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              handleApiCall(_values)
              handleCreateUserSuccess();
              props.datafetch()
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Grid container spacing={3}>
                      {/* {JSON.stringify(values)} */}
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(
                            touched.name && errors.name
                          )}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label={t('Name')}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} style={{ display: 'flex' }}>
                        <Button variant="contained" component="label" style={{ height: 'fit-content' }}>
                          Image Upload
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            label={t('Image')}
                            name="image"
                            onBlur={handleBlur}
                            onChange={handleImageChange}
                            variant="outlined"
                          />
                        </Button>
                        <Typography style={{ width: '80px', height: '80px' }}>
                          {image &&
                            <img
                              src={previewImage}
                              alt=''
                              style={{ width: '100%', height: '100%', marginLeft: '20px' }}
                            />
                          }
                        </Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateUserClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t(modalbtn())}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog open={openCategory} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {t(modalTitle())}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button variant='contained' color='success' onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
})

export default PageHeader;
