import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
// import ReorderIcon from '@mui/icons-material/Reorder';
import PaymentIcon from '@mui/icons-material/Payment';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import QuizIcon from '@mui/icons-material/Quiz';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BadgeIcon from '@mui/icons-material/Badge';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PaymentsIcon from '@mui/icons-material/Payments';
import BallotIcon from '@mui/icons-material/Ballot';

const menuItems = [
  // {
  //   heading: 'Management',
  //   items: [

      {
        name: 'Commerce',
        icon: StorefrontTwoToneIcon,
        link: '/extended-sidebar/management/commerce',
        items: [
          {
            name: 'List',
            icon: BallotIcon,
            link: 'management/commerce/products/list'
          },
          {
            name: 'Trade',
            link: 'management/commerce/products/coin',
            icon: LocalAtmIcon,
          },
          {
            name: 'Withdraw',
            link: 'management/commerce/products/withdraw',
            icon: PaymentsIcon
          },
          {
            name: 'Payment',
            link: 'management/commerce/products/payment',
            icon: PaymentIcon
          },
          {
            name: 'Transaction',
            link: 'management/commerce/products/transection',
            icon: PriceChangeIcon
          },
          {
            name: 'Purchase Order',
            link: 'management/commerce/products/purchase_order',
            icon: InventoryIcon
          },
          // {
          //   name: 'Purchase Details',
          //   link: 'management/commerce/products/purchase_details'
          // },
          {
            name: 'KYC List',
            link: 'management/commerce/products/kyc_list',
            icon: BadgeIcon
          },
          {
            name: 'All User',
            link: 'management/commerce/products/user_list',
            icon: AccountBoxIcon
          },
          {
            name: 'Category',
            link: 'management/commerce/products/category_list',
            icon: CategoryIcon
          },
          {
            name: 'Banner',
            link: 'management/commerce/products/banner',
            icon: ViewCarouselIcon
          },
          {
            name: 'Spin and Win',
            link: 'management/commerce/products/spin',
            icon: EmojiEventsIcon
          },
          {
            name: 'Quiz',
            link: 'management/commerce/products/quiz',
            icon: QuizIcon
          },
          {
            name: 'Predict and Win',
            link: 'management/commerce/products/predict_win',
            icon: BatchPredictionIcon
          },
          // {
          //   name: 'Transaction Tab',
          //   link: 'management/commerce/products/transection_tab'
          // },
        ]
      },

  //   ]
  // },

];

export default menuItems;
