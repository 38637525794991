import { forwardRef, useImperativeHandle, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import wait from 'src/utils/wait';
import * as Yup from 'yup';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  TextField,
  CircularProgress,
  Button,
  DialogContentText,
  Chip,
} from '@mui/material';
import axios from 'axios';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useSnackbar } from 'notistack';
import { baseUrl } from 'src/baseUrl';

const PageHeader = forwardRef((props, ref) => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [initialvalue, setinitialvalue] = useState({ quizTitle: '', questions: [{ question: '', answer: '', options: [] }], })
  const [openProduct, setOpenProduct] = useState(false);

  const [dynamicMenu, setDynamicMenu] = useState({ value: '', name: '' })

  const onDynamicMenuChange = (e) => {
    setDynamicMenu({
      ...dynamicMenu, [e.target.name]: e.target.value,
      value: e.target.value
    })
  }

  function addNewQuestion() {
    let arr = initialvalue.questions;
    setinitialvalue({ questions: [...arr, { question: '', answer: '', options: [] }] });
  }

  const refresh = () => {
    let arr = initialvalue.questions;
    setinitialvalue({ questions: arr });
  }

  const removeQuestion = (i) => {
    let arr = initialvalue.questions;
    arr.splice(i, 1);
    setinitialvalue({ questions: arr })
  }

  const handleClickOpen = () => {
    setOpenProduct(true);
  };

  const handleClose = () => {
    setOpenProduct(false);
  };

  useImperativeHandle(ref, () => ({
    childFunction1(x) {
      setOpen(true);
      setinitialvalue(x)
    },
  }))

  const handleCreateUserOpen = () => {
    setOpen(true);
  };

  const handleCreateUserClose = () => {
    setOpen(false);
    setinitialvalue({
      quizTitle: '', questions: [{ questionId: '', question: '', answer: '', options: [] }],
    })
  };

  const validateOption = (value) => {
    let error;
    if (value <= 4) {
      error = 'please add $ 4 option';
    }
    return error;
  }

  const toastadd = (e) => {
    return e ? `The Quiz was Added successfully` : `Error Creating Quiz`
  }

  const modalbtn = () => {
    return !initialvalue.gameId ? `Add new Quiz` : `Update Quiz`
  }

  const modalTitle = (e) => {
    return e ? `Quiz successfully created.` : `Quiz successfully update.`
  }

  const handleCreateUserSuccess = () => {
    setOpen(false);
  };

  const handleAddCall = (_values) => {
    let params = {};
    Object.assign(params, { "quizTitle": _values.quizTitle });
    let questionList = [];

    _values.questions.forEach((x) => {
      let data = {};
      Object.assign(data, { "question": x.question });
      Object.assign(data, { "options": x.options });
      Object.assign(data, { "answer": x.answer });
      questionList.push(data);
    });

    Object.assign(params, { "questions": questionList });
    axios.post(`${baseUrl}quiz/create`, params).then((response) => {
      enqueueSnackbar(t(toastadd(response.data)), {
        variant: response.data ? 'success' : 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        TransitionComponent: Zoom, autoHideDuration: 2000
      });
      setOpen(false);
      handleClickOpen()
      props.datafetch()
    })
      .catch((err) => {
        console.error(err);
        console.log('err.response', err.response)
        enqueueSnackbar((err), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          TransitionComponent: Zoom
        });
      })

  }

  return (
    <>
      <Grid container justifyContent="end" alignItems="center">
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={handleCreateUserOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Create Quiz')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        // style={{ zIndex: 10000 }}
        open={open}
        onClose={handleCreateUserClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t(!initialvalue.gameId ? 'Add New Quiz' : 'Update Quiz')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new Project to the site'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={initialvalue}
          validationSchema={Yup.object().shape({
            quizTitle: Yup.string()
              .max(255)
              .required(t('QuizTitle Name is required!')),
            questions: Yup.array().of(
              Yup.object().shape({
                question: Yup.string().required("Question required"),
                answer: Yup.string().required("Answer required"),
                options: Yup.array()
                  .required("options required")
                  .min(4, 'Minimum of 4 options')
                  .max(4, 'Maximum of 4 options')
              }))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              await wait(1000);
              setStatus({ success: true });
              setSubmitting(false);
              handleAddCall(_values)
              resetForm();
              setinitialvalue({ quizTitle: '', questions: [{ question: '', answer: '', options: [] }], })
              handleCreateUserSuccess();
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form form onSubmit={handleSubmit}>
              <DialogContent dividers sx={{ p: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.quizTitle && errors.quizTitle)}
                      fullWidth
                      helperText={touched.quizTitle && errors.quizTitle}
                      label={t('quizTitle')}
                      name="quizTitle"
                      onBlur={handleBlur}
                      value={values.gameTitle ? values.gameTitle : values.quizTitle}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  {initialvalue?.questions?.map((e, index) => {
                    return (
                      <>
                        <Grid item xs={6} key={index}>
                          <TextField
                            error={Boolean(touched.question && errors.question)}
                            fullWidth
                            helperText={touched.question && errors.question}
                            label={t('Question')}
                            name="question"
                            onBlur={handleBlur}
                            value={e.question}
                            onChange={(ef) => {
                              e.question = ef.target.value;
                              refresh();
                            }}
                            variant="outlined"
                          />
                          <ErrorMessage name={`questions.${index}.question`} >
                            {msg => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label={t('Add Menu')}
                            name="name"
                            onBlur={handleBlur}
                            value={dynamicMenu.name}
                            onChange={onDynamicMenuChange}
                            variant="outlined"
                            validate={validateOption}
                          />

                          <Button onClick={() => {
                            let arr = e.options;
                            e.options = [...arr, dynamicMenu.name];
                            refresh();
                          }}>
                            Add
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          {e.options.map((nametemp) => {
                            return (
                              <Chip
                                key={nametemp}
                                label={nametemp}
                                value={nametemp}
                                onDelete={() => {
                                  let arr = e.options;
                                  let indexOf = arr.indexOf(nametemp)
                                  arr.splice(indexOf, 1)
                                  e.options = arr;
                                  refresh()
                                }}
                              >
                                {nametemp}
                              </Chip>
                            )
                          })}
                          <ErrorMessage name={`questions.${index}.options`} >
                            {msg => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={Boolean(touched.answer && errors.answer)}
                            fullWidth
                            helperText={touched.answer && errors.answer}
                            label={t('Answer')}
                            name="answer"
                            onBlur={handleBlur}
                            value={e.answer}
                            onChange={(ef) => {
                              e.answer = ef.target.value;
                              refresh();
                            }}
                            variant="outlined"
                            type='number'
                          />
                          <ErrorMessage name={`questions.${index}.answer`} >
                            {msg => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>

                          {(initialvalue.questions.length !== 1)
                            ?
                            <Button
                              color="error"
                              variant="contained"
                              onClick={() => removeQuestion(index)}
                            >
                              Remove Question
                            </Button>
                            : ''}
                        </Grid>
                      </>

                    )
                  })}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        addNewQuestion()
                      }}
                      style={{ marginRight: '5px' }}
                    >
                      Add New Question
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateUserClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                  onClick={modalbtn}
                >
                  {t(modalbtn())}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog open={openProduct} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {t(modalTitle())}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button variant='contained' color='success' onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
})

export default PageHeader;