
import {
  Box, List,
  Divider,
  styled,
  //  ListSubheader
} from '@mui/material';
import { Fragment } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
// import { useTranslation } from 'react-i18next';
// import { Fragment } from 'react';
import SidebarMenuItem from './item';
import menuItems from './items';

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
  background: ${theme.sidebar.menuItemIconColor};
  margin-left: ${theme.spacing(3)};
  margin-right: ${theme.spacing(3)};
  opacity: .25;
`
);

const MenuWrapper = styled(Box)(
  ({ theme }) => `

  &:last-of-type + .MuiDivider-root {
      height: 0;
  }

  .MuiList-root {
    padding: ${theme.spacing(1, 0)};

    .MuiDivider-root {
      background: ${theme.sidebar.menuItemIconColor};
      margin-left: ${theme.spacing(3)};
      margin-right: ${theme.spacing(3)};
    }

    & > .MuiList-root {
      padding: 0;
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;
      
      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 1px 0;
        justify-content: center;
    
        .MuiIconButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          // background-color: ${theme.sidebar.menuItemBg};
          // width: ${theme.spacing(6)};
          width: 100% !important;
          height: ${theme.spacing(6)};
          transition: ${theme.transitions.create(['all'])};
          justify-content: left;
          font-size: ${theme.typography.pxToRem(13)};
          padding: 0;
          position: relative;

          .name-wrapper {
            display: none;
          }

          .MuiBadge-root {
            position: absolute;
            right: 10px;
            top: 11px;

            .MuiBadge-standard {
              width: 100% !important;
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(10)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
  
          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(28)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          &.Mui-active,
          &:hover {
            width: 100% !important;
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiSvgIcon-root {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({ items, path }) => (
  < SubMenuWrapper >
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper >
);

const reduceChildRoutes = ({ ev, path, item }) => {
  const key = uuidv4();

  const exactMatch = item.link
    ? !!matchPath(
      {
        path: item.link,
        end: true
      },
      path
    )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
        {
          path: item.link,
          end: false
        },
        path
      )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  // const { t } = useTranslation();

  return (
    <>
      {menuItems.map((section) => (
        < Fragment key={uuidv4()} >
          <MenuWrapper>
            <List component="div">
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname,
              })}
            </List>
          </MenuWrapper>
          <DividerWrapper />
        </Fragment>
      ))
      }
    </>
    // <>
    //   {menuItems.map((section) => (
    //     <MenuWrapper key={section.heading}>
    //       <List
    //         component="div"
    //         subheader={
    //           <ListSubheader component="div" disableSticky>
    //             {t(section.heading)}
    //           </ListSubheader>
    //         }
    //       >
    //         {renderSidebarMenuItems({
    //           items: section.items,
    //           path: location.pathname
    //         })}
    //       </List>
    //     </MenuWrapper>
    //   ))}
    // </>
  );
}
export default SidebarMenu;
