import { forwardRef, useImperativeHandle, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import wait from 'src/utils/wait';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  TextField,
  CircularProgress,
  Button,
  DialogContentText,
} from '@mui/material';
import axios from 'axios';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useSnackbar } from 'notistack';
import { baseUrl } from 'src/baseUrl';
import useRefMounted from 'src/hooks/useRefMounted';

const PageHeader = forwardRef((props, ref) => {

  const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [initialvalue, setinitialvalue] = useState({ questions: '', option1: '', option2: '', option3: '', option4: '', })
  const [openProduct, setOpenProduct] = useState(false);

  const handleClickOpen = () => {
    setOpenProduct(true);
  };

  const handleClose = () => {
    setOpenProduct(false);
  };

  useImperativeHandle(ref, () => ({
    childFunction1(x) {
      setOpen(true);
      setinitialvalue(x)
    },
  }))

  const handleCreateUserOpen = () => {
    setOpen(true);
  };

  const handleCreateUserClose = () => {
    setOpen(false);
    setinitialvalue({
      quizTitle: '', questions: [{ questions: '', option1: '', option2: '', option3: '', option4: '', }],
    })
  };
  const toastadd = (e) => {
    return e ? `The Predict was Added successfully` : `Error Creating Predict`
  }


  const toastedit = (e) => {
    return e ? `The Predict was Updated successfully` : `Error Updating Predict`
  }

  const modalbtn = () => {
    return !initialvalue.id ? `Add new Predict` : `Update Predict`
  }

  const modalTitle = (e) => {
    return e ? `Predict successfully created.` : `Predict successfully update.`
  }

  const handleCreateUserSuccess = () => {
    setOpen(false);
  };

  const getUsers = async () => {
    try {
      const response = await axios.post(`${baseUrl}paw/questions/all`);
      if (isMountedRef.current) {
        props.setUsers(response.data);
      }
    }
    catch (err) {
      console.error(err);
    }
  }


  const handleAddCall = (_values) => {
    axios.post(`${baseUrl}paw/add/question`, {
      "question": _values.question,
      "option1": _values.option1,
      "option2": _values.option2,
      "option3": _values.option3,
      "option4": _values.option4,
    }).then((response) => {
      enqueueSnackbar(t(toastedit(response.data)), {
        variant: response.data ? 'success' : 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        TransitionComponent: Zoom, autoHideDuration: 2000
      });
      setOpen(false);
      handleClickOpen()
      getUsers()
    })
      .catch((err) => {
        console.error(err);
        console.log("Error", err.response.data);
        enqueueSnackbar((err.response.data.message), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      })
  }

  const handleEditCall = (_values) => {
    axios.post(`${baseUrl}paw/edit/question`, {
      "questionId": _values.id,
      "question": _values.question,
      "option1": _values.option1,
      "option2": _values.option2,
      "option3": _values.option3,
      "option4": _values.option4,
    }).then((response) => {
      enqueueSnackbar(t(toastadd(response.data)), {
        variant: response.data ? 'success' : 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        TransitionComponent: Zoom, autoHideDuration: 2000
      });
      setOpen(false);
      handleClickOpen()
      getUsers()
    })
      .catch((err) => {
        console.error(err);
        console.log("Error", err.response.data);
        enqueueSnackbar((err.response.data.message), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      })
  }

  const handleApiCall = (values) => {
    return !values.id ? handleAddCall(values) : handleEditCall(values)
  }

  return (
    <>
      <Grid container justifyContent="end" alignItems="center">
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={handleCreateUserOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Create Question')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        style={{ zIndex: 10000 }}
        open={open}
        onClose={handleCreateUserClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t(!initialvalue.id ? 'Add New Predict' : 'Update Predict')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new Project to the site'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={initialvalue}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              await wait(1000);
              setStatus({ success: true });
              setSubmitting(false);
              // handleAddCall(_values)
              handleApiCall(_values)
              resetForm();
              setinitialvalue({ question: '', option1: '', option2: '', option3: '', option4: '', })
              handleCreateUserSuccess();
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(touched.question && errors.question)}
                          fullWidth
                          helperText={touched.question && errors.question}
                          label={t('Question')}
                          name="question"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.question}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(touched.option1 && errors.option1)}
                          fullWidth
                          helperText={touched.option1 && errors.option1}
                          label={t('option1')}
                          name="option1"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.option1}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(touched.option2 && errors.option2)}
                          fullWidth
                          helperText={touched.option2 && errors.option2}
                          label={t('Option2')}
                          name="option2"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.option2}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(touched.option3 && errors.option3)}
                          fullWidth
                          helperText={touched.option3 && errors.option3}
                          label={t('Option3')}
                          name="option3"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.option3}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(touched.option4 && errors.option4)}
                          fullWidth
                          helperText={touched.option4 && errors.option4}
                          label={t('Option4')}
                          name="option4"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.option4}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateUserClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                // onClick={modalbtn}
                >
                  {t(modalbtn())}
                  {/* {t(modalbtn(), 'Add Product')} */}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog open={openProduct} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {t(modalTitle())}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button variant='contained' color='success' onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
})

export default PageHeader;
