import {
  useState,
  // useContext,
  // Fragment
  useRef
} from 'react';
import {
  Box, useTheme, Tooltip,
  alpha, Popover, Button,
  // lighten, darken
  Avatar
} from '@mui/material';
import {
  Outlet,
  useNavigate,
  //  NavLink
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import useAuth from 'src/hooks/useAuth';
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import './Sidebar/custom.css';
import SidebarMenu from './Sidebar/SidebarMenu';

const drawerWidth = 240;

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        // height: ${theme.header.height};
        height: 65px;
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        top: 0;
        z-index: 10000;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.md}px) {
            left: ${theme.spacing(0)};
            width: auto;
        }

`
);

const openedMixin = (theme) => ({
  width: drawerWidth,
  zIndex: 10001,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  zIndex: 9999,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));


const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: none;
  }
`
);

const UserAvatar = styled(Avatar)(
  ({ theme }) => `
        height: 100%;
        width: 100%;
        border-radius: ${theme.general.borderRadiusLg};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

const icons = {
  JWT: '/static/images/logo/appLogo.svg',
};

const CollapsedSidebarLayout = () => {
  // const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [sidebarFlag, setSidebarFlag] = useState(false)

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState("cont");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setSidebarFlag(true)
    if (sidebarFlag === false) {
      setSidebarFlag(true)
      localStorage.setItem('isDrawerOpen', true);
      setStyle("css-1s7zf2x-MuiDrawer-docked");
    } else {
      setSidebarFlag(false);
      localStorage.setItem('isDrawerOpen', false);
      setStyle("css-1hsz34s-MuiDrawer-docked");
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('isDrawerOpen', false);
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.Mui-FixedWrapper': {
            '.MuiDrawer-root.MuiDrawer-docked': {
              '.MuiPaper-root': {
                left: 108,
              }
            }
          }
        }}
      >
        <Box sx={{ display: "flex" }} >
          <CssBaseline />
          <HeaderWrapper display="flex" alignItems="center" >
            <Box display="flex" alignItems="center" >
              <Box
                component="span"
                sx={{
                  display: { md: 'none', xs: 'inline-block', lg: 'inline-block' }
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: { md: 'none', xs: 'inline-block', lg: 'inline-block' }
                  }}
                >
                  <Tooltip arrow
                    className={style}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{
                        marginRight: 5,
                        ...(open && { display: "none" })
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <UserBoxButton
                color="primary"
                ref={ref} onClick={handleOpen}>
                <UserAvatar alt={user?.name} src={icons.JWT} />
              </UserBoxButton>
              <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                style={{ zIndex: 10000 }}
              >
                <MenuUserBox
                  sx={{
                    minWidth: 210
                  }}
                  display="flex"
                >
                  <Avatar variant="rounded" alt={icons.JWT} src={icons.JWT} />
                </MenuUserBox>
                <Divider
                  sx={{
                    mb: 0
                  }}
                />

                <Box m={1}>
                  <Button color="primary" fullWidth onClick={handleLogout}>
                    <LockOpenTwoToneIcon
                      sx={{
                        mr: 1
                      }}
                    />
                    {t('Sign out')}
                  </Button>
                </Box>
              </Popover>
            </Box>
          </HeaderWrapper>

          <Drawer variant="permanent" open={open}>
            <DrawerHeader >
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <SidebarMenu />
          </Drawer>
          <Divider />
          <Box component="main" style={{ width: 'auto' }} sx={{ flexGrow: 1, pt: 5 }}>
            <Outlet context={{ open }} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CollapsedSidebarLayout;
