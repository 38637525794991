/* eslint-disable jsx-a11y/label-has-for */
import { forwardRef, useImperativeHandle, useState } from 'react';
// import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import wait from 'src/utils/wait';
import { baseUrl } from 'src/baseUrl';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  // Radio,
  // TextField,
  // FormControlLabel,
  CircularProgress,
  Button,
  DialogContentText,
  // IconButton,
  // MenuItem,
} from '@mui/material';
import axios from 'axios';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useSnackbar } from 'notistack';
// import useRefMounted from 'src/hooks/useRefMounted';

const PageHeader = forwardRef((props, ref,
  //  setUsers
) => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [image, setImage] = useState('')
  // const isMountedRef = useRefMounted();
  // const [previousImage, setPreviousImage] = useState('')
  const [initialvalue, setinitialvalue] = useState({
    name: '', image
  })
  const [previewImage, setPreviewImage] = useState([])
  const [openCategory, setOpenCategory] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const handleClickOpen = () => {
    setOpenCategory(true);
  };

  const handleClose = () => {
    setOpenCategory(false);

  };
  useImperativeHandle(ref, () => ({
    childFunction1(x) {
      setOpen(true);
      setPreviewImage(x.image)
      setinitialvalue(x)
    },

  }))

  const handleCreateUserOpen = () => {
    setOpen(true);
  };

  const handleCreateUserClose = () => {
    setOpen(false);
    setinitialvalue({
      name: '', image
    })
    setPreviewImage([])
  };

  const imageAdd = (e) => {
    return e ? `The Image was successfully Select` : `Error Image select`
  }
  const toastadd = (e) => {
    return e ? `The Banner was Added successfully` : `Error Banner Category`
  }
  // const toastedit = (e) => {
  //   return e ? `The Banner was Updated successfully` : `Error Updating Banner`
  // }

  const modalbtn = () => {
    return !initialvalue.id ? `Add new Banner` : `Update Banner`
  }

  const handleCreateUserSuccess = () => {
    setOpen(false);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0])
    setinitialvalue({ ...initialvalue, 'image': e.target.files[0] })
    // if (e.target.files && e.target.files[0] && image.width === 340 && image.height === 170) {
    //   setPreviewImage(URL.createObjectURL(e.target.files[0]));
    //   setErrorMessage('')
    // } else {
    //   setErrorMessage('Please select an image with dimensions of 340px x 170px.');
    // }
    const file = e.target.files[0];
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      if (e.target.files && e.target.files[0] && image.width === 340 && image.height === 170) {
        setPreviewImage(URL.createObjectURL(file));
        setErrorMessage('');
      } else {
        setErrorMessage('Please select an image with dimensions of 340px x 170px.');
      }
    }

    enqueueSnackbar(t(imageAdd(e.target.files)), {
      variant: e.target.files ? 'success' : 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      TransitionComponent: Zoom, autoHideDuration: 2000
    });

  }

  const handleAddCall = () => {
    let data = new FormData();
    data.append('banner', image);
    axios.post(`${baseUrl}shopping/banner/add`, data).then((response) => {
      enqueueSnackbar(t(toastadd(response.data)), {
        variant: response.data ? 'success' : 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        TransitionComponent: Zoom, autoHideDuration: 2000
      });
      setOpen(false);
      handleClickOpen()
      props.datafetch()
    })
      .catch((err) => {
        console.error(err);
        console.log("Error", err.response.data);
        enqueueSnackbar((err.response.data.message), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      })
  }

  // const handleApiCall = (values) => {
  //   return !values.id ? handleAddCall(values) : handleEditCall(values)
  // }

  return (
    <>
      <Grid container justifyContent="end" alignItems="center">
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={handleCreateUserOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Create Category')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        style={{ zIndex: 10000 }}
        open={open}
        onClose={handleCreateUserClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t(initialvalue.id ? 'Update Project' : 'Add New Project')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new Project to the site'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={initialvalue}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              await wait(1000);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              // handleApiCall(_values)
              handleAddCall(_values)
              handleCreateUserSuccess();
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            // handleChange,
            handleSubmit,
            isSubmitting,
            // touched,
            // values
          }) => (
            <form form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Grid container spacing={3}>
                      {/* {JSON.stringify(values)} */}
                      {/* <Grid item xs={6}>
                        <TextField
                          error={Boolean(
                            touched.name && errors.name
                          )}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label={t('Name')}
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid> */}
                      <Grid item xs={6} style={{ display: 'flex' }}>
                        <Button variant="contained" component="label" style={{ height: 'fit-content' }}>
                          Image Upload
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            label={t('Image')}
                            name="image"
                            onBlur={handleBlur}
                            onChange={handleImageChange}
                            // value={values.image}
                            variant="outlined"
                          />
                        </Button>
                        <Typography style={{ width: '80px', height: '80px' }}>
                          <Typography style={{ width: '80px', height: '80px' }}>
                            {image &&
                              <img src={previewImage} alt='' style={{ width: '100%', height: '100%', marginLeft: '20px' }} />
                            }
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    {errorMessage && <Typography style={{ color: 'red' }}>{errorMessage}</Typography>}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateUserClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t(modalbtn())}
                </Button>
                {/* <Button
                  type="submit"
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                  onClick={modalbtn}
                >
                  Add Category
                </Button> */}
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog open={openCategory} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Category successfully created.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button variant='contained' color='success' onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
})

export default PageHeader;
